import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import MobileMenu from "../components/MobileMenu";
import HeroSection from "../components/HeroSection";
import AboutMeSection from "../components/AboutMeSection";
import ServicesGrid from "../components/ServicesGrid";
import CallToActionSection from "../components/CallToActionSection";
import Footer from "../components/Footer";

function Home() {
  return (
    <div>
      <Helmet>
        <title>Repta | Home</title>
        <meta
          name="description"
          content="Repta - Website development, logo design, corporate image development. Quality and professional solutions for your business."
        />
        <meta
          name="keywords"
          content="websites, logos, business design, professional solutions, web development"
        />
        <meta name="author" content="UAB Repta" />
        <meta property="og:title" content="Repta | Home" />
        <meta
          property="og:description"
          content="Repta - Website development, logo design, corporate image development. Quality and professional solutions for your business."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.repta.eu" />
        <meta
          property="og:image"
          content="https://www.repta.eu/images/repta_black-1.png"
        />
      </Helmet>
      <Header />
      <MobileMenu />
      <HeroSection />
      <AboutMeSection />
      <ServicesGrid />
      <CallToActionSection />
      <Footer />
    </div>
  );
}

export default Home;
