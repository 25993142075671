import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/MobileMenu.scss";

function MobileMenu() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="mobile-menu">
      {/* Кнопка меню */}
      <button
        className={`menu-toggle ${menuOpen ? "white" : ""}`}
        onClick={toggleMenu}
      >
        {menuOpen ? "X" : "MENU"}
      </button>

      {/* Меню */}
      <div className={`menu-drawer ${menuOpen ? "open" : ""}`}>
        <nav className="menu-links">
          <Link to="/">Home</Link>
          <Link to="/services">Contact us</Link>
          <Link to="/portfolio">Portfolio</Link>
          <Link to="/about">About us</Link>
          <Link to="/contact">Contacts</Link>
          <a
            href="https://www.instagram.com/repta.tech/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
        </nav>
      </div>
    </div>
  );
}

export default MobileMenu;
