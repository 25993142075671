import React from "react";

function AboutMeSection() {
  return (
    <section className="bg-customGray  text-white py-20 px-10">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center space-y-10 md:space-y-0">
        {/* Фото слева */}
        <div className="flex-shrink-0">
          <img
            src="/images/maksim.jpg"
            alt="Максим"
            className="w-64 h-84 object-cover rounded-md"
          />
        </div>
        {/* Текст справа */}
        <div className="md:ml-10">
          <h2 className="text-4xl font-bold mb-4">Maksim Repkin</h2>
          <p className="mb-4 text-lg">UAB Repta, CEO</p>
          <p className="mb-4 text-lg">
            Repta specialises in modern websites, logos, branding style and
            brand design. From business cards and menus for restaurants to
            labels and packaging, we also create business-specific elements,
            offering tailor-made solutions for each project
          </p>
          <p className="text-lg">
            Our goal is to make your projects beautiful, functional and
            convenient for your customers
          </p>
        </div>
      </div>
    </section>
  );
}

export default AboutMeSection;
