import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import MobileMenu from "../components/MobileMenu";
import Contacts from "../components/Contacts";
import Footer from "../components/Footer";

const Contact = () => {
  return (
    <div>
      <Helmet>
        <title>Repta | Contacts</title>
        <meta
          name="description"
          content="Repta - Contact us. Telephone, email and address for your convenience."
        />
        <meta
          name="keywords"
          content="contacts, phone, email, address, websites, logos"
        />
        <meta name="author" content="UAB Repta" />
        <meta property="og:title" content="Repta | Contacts"></meta>
        <meta
          property="og:description"
          content="Repta - Contact us. Telephone, email and address for your convenience."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.repta.eu/Contact" />
        <meta
          property="og:image"
          content="https://www.repta.eu/images/repta_black-1.png"
        />
      </Helmet>
      <Header />
      <MobileMenu />
      <Contacts />
      <Footer />
    </div>
  );
};

export default Contact;
