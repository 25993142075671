import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import MobileMenu from "../components/MobileMenu";
import AboutUs from "../components/AboutUs";
import Footer from "../components/Footer";

const About = () => {
  return (
    <div>
      <Helmet>
        <title>Repta | About us</title>
        <meta
          name="description"
          content="Repta - A young, dynamic company offering innovative solutions for your business. Find out more about us."
        />
        <meta
          name="keywords"
          content="about us, websites, logo design, professional solutions"
        />
        <meta name="author" content="UAB Repta" />
        <meta property="og:title" content="Repta | About us"></meta>
        <meta
          property="og:description"
          content="Repta - A young, dynamic company offering innovative solutions for your business. Find out more about us."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.repta.eu/about" />
        <meta
          property="og:image"
          content="https://www.repta.eu/images/repta_black-1.png"
        />
      </Helmet>
      <Header />
      <MobileMenu />
      <AboutUs />
      <Footer />
    </div>
  );
};

export default About;
