import React from "react";
import "../styles/ContactUs.scss";

const ContactUs = () => {
  return (
    <section className="contact-us">
      {/* Заголовок */}
      <div className="contact-header">
        <h1 className="contact-title">?</h1>
        <p className="contact-subtitle">
          Tell us more about yourself and your project
        </p>
      </div>

      {/* Форма */}
      <form
        action="https://formspree.io/f/xovqbeve" // Замените на ваш Formspree endpoint
        method="POST"
        className="contact-form"
      >
        {/* Выбор услуги */}
        <label htmlFor="service">Select a service:</label>
        <select id="service" name="service" required>
          <option value="">Choose</option>
          <option value="website">Website development</option>
          <option value="logo">Logo design</option>
          <option value="branding">Branded business style</option>
        </select>

        {/* Детали проекта */}
        <label htmlFor="projectDetails">Describe the project:</label>
        <textarea
          id="projectDetails"
          name="projectDetails"
          placeholder="Enter project details..."
          required
        ></textarea>

        {/* Контактная информация */}
        <label htmlFor="name">Your name:</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Please enter your name..."
          required
        />

        {/* Контактная информация */}
        <label htmlFor="name">Name of the company or project:</label>
        <input
          type="text"
          id="name"
          name="name"
          placeholder="Enter companies or project name..."
          required
        />

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          placeholder="Please enter your email address..."
          required
        />

        {/* Как узнали */}
        <label htmlFor="referral">How did you hear about us?</label>
        <select id="referral" name="referral" required>
          <option value="">Choose</option>
          <option value="instagram">Instagram</option>
          <option value="google">You found it on the internet</option>
          <option value="recommendation">Recommendations</option>
          <option value="ads">Advertising portals (e.g. skelbiu.lt)</option>
        </select>

        {/* Кнопка отправки */}
        <button type="submit" className="submit-button">
          Send request
        </button>
      </form>
    </section>
  );
};

export default ContactUs;
