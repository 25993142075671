import React from "react";
import { Link } from "react-router-dom";
import "../styles/Header.scss";
import "../styles/HeroSection.scss";

function Header() {
  return (
    <header className="top-0 left-0 w-full flex justify-between items-center px-4 py-2 z-10">
      {/* Ссылки слева */}
      <div className="flex space-x-6">
        <Link to="/" className="navLink">
          Home
        </Link>
        <Link to="/services" className="navLink">
          Contact us
        </Link>
      </div>
      {/* Ссылки справа */}
      <div className="flex space-x-6">
        <Link to="/portfolio" className="navLink">
          Portfolio
        </Link>
        <Link to="/about" className="navLink">
          About us
        </Link>
        <Link to="/contact" className="navLink">
          Contacts
        </Link>
      </div>
    </header>
  );
}

export default Header;
