import React from "react";
import { Link } from "react-router-dom";
import { startSlideshow, stopSlideshow } from "../utils/slideshow";
import "../styles/ServicesGrid.scss";

const ServicesGrid = () => {
  return (
    <section className="grid-section">
      <div className="grid-container">
        {/* Разработка веб-сайтов */}
        <div
          className="grid-item website-design"
          onMouseEnter={() =>
            startSlideshow("website-design", [
              "/images/example_site_10.jpg",
              "/images/example_site_4.jpg",
              "/images/example_site_9.jpg",
              "/images/example_site_1.jpg",
              "/images/example_site_7.jpg",
              "/images/example_site_3.jpg",
              "/images/example_site_5.jpg",
              "/images/example_site_6.jpg",
              "/images/example_site_2.jpg",
              "/images/example_site_8.jpg",
            ])
          }
          onMouseLeave={() => stopSlideshow("website-design")}
        >
          <img id="website-design" className="slideshow-image" alt="" />
          <p className="item-text bottom-left">Website development</p>
        </div>

        {/* Наши услуги */}
        <div className="grid-item large-item main-services">
          <p className="item-text top-left">
            Our key
            <br />
            services we focus on
          </p>
        </div>

        {/* Разработка логотипов */}
        <div
          className="grid-item logo-design"
          onMouseEnter={() =>
            startSlideshow("logo-design", [
              "/images/pic_7.jpg",
              "/images/pic_4.jpg",
              "/images/pic_9.jpg",
            ])
          }
          onMouseLeave={() => stopSlideshow("logo-design")}
        >
          <img id="logo-design" className="slideshow-image" alt="" />
          <p className="item-text bottom-left">Logo design</p>
        </div>

        {/* Разработка визитных карточек */}
        <div
          className="grid-item card-design"
          onMouseEnter={() =>
            startSlideshow("card-design", [
              "/images/pic_2.jpg",
              "/images/pic_1.jpg",
              "/images/pic_5.jpg",
              "/images/pic_8.jpg",
              "/images/pic_10.jpg",
              "/images/pic_3.jpg",
              "/images/pic_6.jpg",
            ])
          }
          onMouseLeave={() => stopSlideshow("card-design")}
        >
          <img id="card-design" className="slideshow-image" alt="" />
          <p className="item-text bottom-left">Branded business style</p>
        </div>

        {/* Портфолио */}
        <div className="grid-item portfolio">
          <Link to="/portfolio" className="navLink">
            Portfolio
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ServicesGrid;
