import React from "react";
import "../styles/Header.scss";
import "../styles/HeroSection.scss";

function HeroSection() {
  return (
    <section className="hero-section">
      <h1 className="hero-title">WEB CREATION</h1>
      <div className="sculpture-container">
        <img
          src="/images/sculpture.svg"
          alt="Sculpture"
          className="sculpture"
        />
        <p className="company-name">REPTA.TECH</p>
      </div>
    </section>
  );
}

export default HeroSection;
