import React, { useState } from "react";
import "../styles/PortfolioSection.scss";

const PortfolioSection = () => {
  const [expandedRows, setExpandedRows] = useState({});

  const projects = [
    {
      client: "Vilnius Helps, VšĮ",
      project: "Website",
      industry: "Communication and business",
      year: 2024,
      details: {
        description: `
          This interactive website is a real help for people living and visiting Vilnius. <br /> 🔹 The website is available in three languages - Lithuanian, English and Russian. <br />
          🔹 It has a handy calendar where you can view and select the events or services on the day that suits you best. <br /> 🔹 Modern and attractive design, easy to navigate. <br /> 🔹 Simple and quick registration forms - everything you need for your convenience. <br /> 💡 We designed this project to create a friendly, modern and practical space for every user. <br /> 
        `,
        images: [
          "/images/example2.png",
          "/images/example3.png",
          "/images/example4.png",
          "/images/example5.png",
        ],
      },
      link: "https://www.vilniushelps.lt",
    },
    {
      client: "@baker_street_vilnius",
      project: "Website",
      industry: "Confectionery",
      year: 2024,
      details: {
        description: `
          This website has been designed for confectionery businesses to provide visitors with a modern, user-friendly and functional platform. <br /> 🔹 Interactive menus: a convenient solution that allows visitors to browse the products and services on offer. <br />
          🔹 About us section: provides an insight into the history and values of the business. <br /> 🔹 Video content: video is integrated into the website to help better showcase the uniqueness of the business. <br /> 🔹 Bilingual design: the page is available in two languages, ensuring usability for a wider audience. <br /> 🔹 Gallery: high quality photos of cakes and desserts, viewable in larger format. <br /> 💡 We've created a simple, aesthetic and intuitive design that perfectly conveys the values of the confectionery business and helps you attract new customers. <br /> 
        `,
        images: [
          "/images/example6.png",
          "/images/example7.png",
          "/images/example8.png",
          "/images/example9.png",
        ],
      },
      link: "https://bkr.lt/",
    },
    {
      client: "Läuft germany",
      project: "Clothing labels",
      industry: "Clothing",
      year: 2024,
      details: {
        description:
          "We've created minimalist clothing labels that are stylish and aesthetically pleasing. <br /> 💡 The focus is on high quality image representation that highlights the brand's distinctiveness.",
        images: ["/images/lauft.jpg"],
      },
    },
    {
      client: "Restaurant «Positano»",
      project: "Restaurant menu",
      industry: "Restaurant",
      year: 2024,
      details: {
        description:
          "We've created an elegant and functional menu design for Positano that reflects Mediterranean inspirations. <br /> 💡 The unique combination of colours and decorative elements highlights the restaurant's uniqueness and gives the menu a cosy and aesthetic feel.",
        images: ["/images/positano.jpg"],
      },
    },
    {
      client: "Iron rhino detailing",
      project: "Logo and business card",
      industry: "Car service",
      year: 2024,
      details: {
        description:
          "We created a modern and distinctive logo for Iron Rhino Detailing, an automotive detailing company. <br /> 🔹 The logo reflects the strength, quality and professionalism that are the company's core values. <br /> 💡 Every detail has been carefully selected to reflect the precision and exclusivity of the car care service.",
        images: ["/images/iron.jpg"],
      },
    },
    {
      client: "@baker_street_vilnius",
      project: "Website",
      industry: "Confectionery",
      year: 2024,
      details: {
        description:
          "We created an elegant logo and stylish business card design for Baker Street Vilnius confectionery business. <br /> 🔹 The logo reflects the warm and cosy atmosphere offered by the patisserie, while the design of the business card emphasises the high quality and attention to detail. <br /> 💡 This project helps create a solid and professional brand image.",
        images: ["/images/pic_4.jpg", "/images/pic_1.jpg", "/images/pic_6.jpg"],
      },
    },
    {
      client: "Festivalis ABC",
      project: "Example website",
      industry: "Events",
      year: 2023,
      details: {
        description:
          "We created a modern and functional website for events. It is attractive and easy to use, helping you to communicate effectively with your audience and expand your business opportunities.",
        images: ["/images/festival.png"],
      },
    },
    {
      client: "ART-TIMES",
      project: "Example website",
      industry: "Art",
      year: 2023,
      details: {
        description:
          "We've created a modern and functional website to showcase your artwork. The site is attractive and easy to use, helping you to communicate effectively with your audience and expand your business opportunities.",
        images: ["/images/art-school.png"],
      },
    },
    {
      client: "M.D. confectionery",
      project: "Example logo",
      industry: "Confectionery",
      year: 2023,
      details: {
        description:
          "We created a unique logo that reflects the confectionery's values and identity. This logo helps to create a professional and memorable brand image.",
        images: ["/images/pic_7.jpg"],
      },
    },
    {
      client: "Portfolio website",
      project: "Representative photo portfolio website",
      industry: "Art",
      year: 2023,
      details: {
        description:
          "We've created a modern and functional website to showcase your photo portfolio. The site is attractive and easy to use, helping you to communicate effectively with your audience and expand your business opportunities.",
        images: ["/images/photo_portfolio.png"],
      },
    },
    {
      client: "Travel together",
      project: "Example website",
      industry: "Leisure time",
      year: 2023,
      details: {
        description:
          "We have created a modern and functional website to showcase Lithuania's attractions. The site is attractive and easy to use, helping you to communicate effectively with your audience and expand your business opportunities.",
        images: ["/images/keliauk_kartu.png"],
      },
    },
    {
      client: "Travel website",
      project: "Example website",
      industry: "Travel, leisure time",
      year: 2023,
      details: {
        description:
          "We designed a modern and functional website to showcase the road agency. The site is attractive and easy to use, helping you to communicate effectively with your audience and expand your business opportunities.",
        images: ["/images/travel_agency.png"],
      },
    },
  ];

  const toggleRow = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <section className="portfolio-section">
      <div className="portfolio-header">
        <h1>Total: {projects.length}</h1>
        <h1>Portfolio</h1>
      </div>
      <table className="portfolio-table">
        <thead>
          <tr>
            <th>Customer</th>
            <th>Project</th>
            <th>Industry</th>
            <th>Year</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {projects.map((project, index) => (
            <>
              <tr
                key={index}
                className={`portfolio-row ${
                  expandedRows[index] ? "expanded" : ""
                }`}
              >
                <td>{project.client}</td>
                <td>{project.project}</td>
                <td>{project.industry}</td>
                <td>{project.year}</td>
                <td>
                  <button
                    className="plus-icon"
                    onClick={() => toggleRow(index)}
                  >
                    {expandedRows[index] ? "-" : "+"}
                  </button>
                </td>
              </tr>
              {expandedRows[index] && (
                <tr className="portfolio-details">
                  <td colSpan="5">
                    <div className="details-content">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: project.details.description,
                        }}
                      ></p>
                      {project.link && (
                        <a
                          href={project.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="project-link"
                        >
                          Go to the website
                        </a>
                      )}
                      <div className="details-images">
                        {project.details.images.map((image, imgIndex) => (
                          <img
                            key={imgIndex}
                            src={image}
                            alt={`Project ${index + 1} Image ${imgIndex + 1}`}
                          />
                        ))}
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default PortfolioSection;
