import React from "react";
import "../styles/CallToActionSection.scss";

const CallToActionSection = () => {
  return (
    <section className="call-to-action-section">
      <div className="text-container">
        <p className="highlighted-text">
          If you're looking for a strong partner who will give everything to
          your project,
        </p>
        <p className="highlighted-text_2">We are your choice</p>
      </div>
    </section>
  );
};

export default CallToActionSection;
