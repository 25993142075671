import React from "react";
import "../styles/About.scss";

const AboutUs = () => {
  return (
    <section className="about-section">
      {/* Заголовок */}
      <div className="about-header">
        <h1>ABOUT US</h1>
      </div>

      {/* Первый блок: текст слева, фотография справа */}
      <div className="content-block">
        <div className="text">
          <h2>Our vision</h2>
          <p>
            We believe in the power of teamwork, creativity and perseverance.
            These qualities help us to achieve high results and grow with our
            clients.
          </p>
          <p>
            If you're looking for a partner who's ready to put their all into
            every project, we're your choice.
          </p>
        </div>
        <div className="image">
          <img
            src="/images/repta_black-1.png"
            alt="Įmonės logotipas"
            className="round-image"
          />
        </div>
      </div>

      {/* Второй блок: фотография слева, текст справа */}
      <div className="content-block reverse">
        <div className="image">
          <img
            src="/images/maksim-removebg-preview.png"
            alt="Mūsų direktorius"
            className="portrait-image"
          />
        </div>
        <div className="text">
          <h2>Our history</h2>
          <p>
            We are a young and energetic company, founded in 2023.Despite our
            short journey, we are growing rapidly and confidently earning the
            trust of our customers. Every new project is an opportunity for us
            to showcase our talents and prove that even a young company can
            offer quality solutions.
          </p>
          <p>
            Our approach is based on continuous learning and the pursuit of
            excellence. We follow the latest trends in web development and
            implement them in our work. It is important that every website we
            create not only meets modern standards, but also brings real
            benefits to our clients.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
